<template>
  <PageTitleTop>
    <template v-slot:title>提现记录</template>
    <Row>
      <Col span="6">
        <DatePicker
          :model-value="searchData.time"
          format="yyyy-MM-dd"
          type="daterange"
          placement="bottom-end"
          placeholder="选择时间段"
          style="width: 200px"
          @on-change="dateChange"
        ></DatePicker>
      </Col>
      <Col span="4">
        <Select
          v-model="searchData.otherTime"
          style="width: 200px"
          clearable
          @on-change="timeRangeChange"
        >
          <Option :value="1">最近一周</Option>
          <Option :value="2">最近一个月</Option>
          <Option :value="3">最近三个月</Option>
          <Option :value="4">最近半年</Option>
          <Option :value="5">最近一年</Option>
        </Select>
      </Col>
    </Row>
    <Table
      :columns="columns"
      :data="dataList"
      :fixed-shadow="'auto'"
      class="table-box"
    >
      <template #orderDetail="{ row }">
        <div class="order-detail-td" @click="toBankBackOrder(row)">
          <div>
            {{
              '订单【' + row.serialNo + '】' + ' 使用余额【' + row.amount + '】'
            }}
          </div>
          <div class="secondary-info">
            {{ row.transDateTime }}
          </div>
        </div>
      </template>
      <template #amount="{ row }">
        <div class="amount">
          {{ `+${row.amount}` }}
        </div>
      </template>
    </Table>
    <Row justify="end" class="table-page">
      <Page
        v-model="pageInfo.pageIndex"
        :total="pageInfo.total"
        :page-size="pageInfo.pageSize"
        @on-change="pageChange"
      />
    </Row>
  </PageTitleTop>
</template>

<script>
import { initWithDrawListHeader } from '../config'
import moment from 'moment'
export default {
  name: 'FrozenMoney',
  data () {
    return {
      searchData: {
        time: [],
        otherTime: '',
      },
      pageInfo: {
        pageIndex: 1,
        total: 0,
        pageSize: 10,
      },
      dataList: [],
    }
  },
  setup () {
    const columns = initWithDrawListHeader()
    return {
      columns,
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    toBankBackOrder (data) {
			this.showSpin()
      // console.log(data)
      const params = {
        serialNo: data.serialNo,
        transDate: data.transDate,
      }
      this.$store
        .dispatch('accountMoney/BankBackOrder', params)
        .then((res) => {
					this.$Spin.hide()
          if (res.returnCode === '1') {
						window.open(res.result.authUrl, '_self')
          }
        })
        .catch(() => {})
    },
    timeRangeChange (val) {
      if (!val) {
        return
      }
      this.searchData.time = []
      this.searchData.time[1] = moment().format('YYYY-MM-DD')
      switch (val) {
        case 1:
          this.searchData.time[0] = moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD')
          break
        case 2:
          this.searchData.time[0] = moment()
            .subtract(1, 'month')
            .format('YYYY-MM-DD')
          break
        case 3:
          this.searchData.time[0] = moment()
            .subtract(3, 'month')
            .format('YYYY-MM-DD')
          break
        case 4:
          this.searchData.time[0] = moment()
            .subtract(6, 'month')
            .format('YYYY-MM-DD')
          break
        case 5:
          this.searchData.time[0] = moment()
            .subtract(1, 'year')
            .format('YYYY-MM-DD')
          break
      }
      this.getDetail()
    },
    showSpin () {
      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h(
              'div',
              {
                style: {
                  color: '#d12b23',
                  fontSize: '22px',
                },
              },

              '正在加载银行数据。请等待...',
            ),
          ])
        },
      })
    },
    getDetail () {
      this.showSpin()
      const time = this.searchData.time.length ? this.searchData.time : ['', '']
      const params = {
        page: this.pageInfo.pageIndex,
        rows: this.pageInfo.pageSize,
        startDate: time[0].replace(/-/g, ''),
        endDate: time[1].replace(/-/g, ''),
        serialNo: '',
        orderNo: '',
        transType: 13, // 提现type
      }
      this.$store
        .dispatch('accountMoney/GetDetail', params)
        .then((res) => {
          this.$Spin.hide()
          if (res.returnCode === '1') {
            this.dataList = res.result.rows
            this.pageInfo.total = res.result.records
          }
        })
        .catch(() => {})
    },
    toDetail () {},
    pageChange () {
      this.getDetail()
    },
    dateChange (val) {
      this.searchData.otherTime = ''
      this.searchData.time = val
      this.getDetail()
    },
  },
  components: {},
}
</script>

<style lang="less" scoped>
.code-row-bg {
  padding: 20px 0;
}
.header-area {
  border-bottom: 2px solid #f4f5f7;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.table-box {
  margin-top: 20px;
}
.amount {
  font-size: 18px;
}
</style>
